<script setup lang="ts">
defineProps<{
    citations: string[];
}>();

const route = useRoute();
</script>

<template>
    <section
        class="flex flex-col gap-3 pt-11 text-xs"
        style="content-visibility: auto"
    >
        <ol
            class="flex list-decimal flex-col items-start gap-1 pl-4 marker:content-[counter(item)')']"
        >
            <li
                v-for="(line, index) in citations"
                :id="`footnote-${index + 1}`"
                :key="index"
                class="scroll-mt-[5em] p-2 leading-4"
                :class="{
                    highlighted:
                        route.hash.slice(1) === `footnote-${index + 1}`,
                }"
            >
                {{ line }}
                <a
                    :href="`#footnote-${index + 1}-link`"
                    aria-label="Back to content"
                    >↩</a
                >
            </li>
        </ol>
    </section>
</template>

<style scoped>
ol li {
    counter-increment: item;
}

.highlighted {
    animation: highlight 4s;
}

@keyframes highlight {
    from {
        @apply bg-gray-300;
    }

    to {
        @apply bg-white;
    }
}
</style>
